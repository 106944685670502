<template>
  <div>
    <v-window
      v-model="step"
      class="mb-15 pb-4"
    >
      <v-window-item
        v-for="(slide, index) in slides"
        :key="index"
      >
        <div class="text-h2 mb-2 text-center">
          {{ slide.title }}
        </div>

        <div class="text-body-1 text-center text-primary-darken">
          {{ slide.description }}
        </div>
        <!-- v-container class="pt-1 pb-0">
          <v-row class="fill-height">
            <v-col cols="12">
              <v-alert
                class="stepperalert pl-4"
                border-color="primary"
                closable
                density="compact"
              >
              </v-alert>
            </v-col>
          </v-row>
        </v-container -->

        <v-container
          :fluid="slide.fluid"
          :class="{ 'container-dense': slide.dense }"
        >
          <v-row class="fill-height">
            <v-col cols="12">
              <component
                :is="slide.component"
                :ref="slide.component + '-' + index"
                v-bind="slide.props"
                :key="'assistant-component-' + step"
                :class="slide.class"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>

    <v-footer
      app
      order="-1"
      color="rgba(255,255,255,.0)"
    >
      <v-container class="my-2">
        <v-row>
          <v-col cols="auto">
            <v-btn
              :disabled="step === 0"
              variant="outlined"
              size="44"
              color="primary-darken"
              icon="mdi-arrow-left"
              pill
              @click="prev"
            >
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <v-btn
              variant="elevated"
              color="primary-darken"
              rounded
              size="large"
              :loading="loading"
              append-icon="mdi-chevron-right"
              data-cy="next"
              @click="next"
            >
              Speichern & weiter
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  props: {
    slides: {
      type: Array,
      default: () => {},
    },
  },
  emits: ['finish'],
  data() {
    return {
      step: 0,
      loading: false,
    }
  },
  mounted() {
    const step = this.$route.query.step
    if (step) {
      this.step = parseInt(step)
    }
  },
  methods: {
    async next() {
      if (this.slides[this.step].submit) {
        this.loading = true
        const result = await this.$refs[
          this.slides[this.step].component + '-' + this.step
        ][0].submit()
        this.loading = false
        if (!result) {
          return false
        }
      }
      if (this.step + 1 === this.slides.length) {
        this.$emit('finish')
      } else {
        this.step = this.step + 1
      }
    },
    prev() {
      this.step = this.step - 1 < 0 ? 0 : this.step - 1
    },
  },
}
</script>
<style lang="scss">
.v-footer--fixed {
  z-index: 6;
}
</style>
